import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`You still have time to register for the Open! You can make up 18.1
today.   Sign up now at `}<a parentName="em" {...{
            "href": "http://games.crossfit.com%C2%A0and"
          }}>{`http://games.crossfit.com%C2%A0and`}</a>{` be sure to
add yourself to team CrossFit the Ville.  Each Saturday the Open wod
will be the class wod at 9:00 & 10:00 and we will be judging until 12pm.
 So come out to participate in the Open and cheer on your fellow
CrossFitters!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Remember you must submit your score before 8:00pm tonight!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      